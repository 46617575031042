// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./slice";
import Userslice from "./Userslice";
import collapsedSlice from "./collapsedSlice";

const store = configureStore({
  reducer: {
    theme: themeReducer,
    user: Userslice,
    collapsed: collapsedSlice,
  },
});

export default store;
