import { ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import MainRouts from "./Routes/MainRouts";
import "react-toastify/dist/ReactToastify.css";
import nlNL from "antd/lib/locale/nl_NL";

function App() {
  // استخدام darkmode
  // const { isDarkMode, toggleDarkMode } = useTheme();

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  return (
    <div className="font-sans ">
      <ConfigProvider
        locale={nlNL}
        theme={{
          token: {
            borderRadiusLG: 4,
            borderRadiusOuter: 4,
            borderRadiusXS: 4,
            borderRadiusSM: 4,
            fontSize: 18,
            borderRadius: 4,
            colorPrimary: "#2563dc",
            colorTextBase: isDarkMode ? "#fefefe" : "#141723",
            colorBgBase: isDarkMode ? "#141723" : "#fefefe",
          }, // لون المعلومات
          components: {
            Table: {
              headerColor: "rgba(150,150,150,0.88)",
            },
            Menu: {
              colorPrimaryBorder: "rgb(255,255,255)",
              boxShadowSecondary: "",
              colorSplit: "#fffff",
            },
          },
          // algorithm: isDarkMode ? "dark" : undefined, // استخدم "dark" إذا كان في وضع الظلام
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
      >
        <MainRouts />
      </ConfigProvider>
    </div>
  );
}

export default App;
