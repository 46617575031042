// src/themeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const collapsedSlice = createSlice({
  name: "collapsed",
  initialState: {
    collapsed: true,
  },
  reducers: {
    changecollapsed(state, action) {
      state.collapsed = action.payload;
    },
  },
});

export const { changecollapsed } = collapsedSlice.actions;
export default collapsedSlice.reducer;
