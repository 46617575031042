import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || {
    token: "",
    full_name: "",
    image_url: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = {
        ...action.payload.info,
      };
      localStorage.setItem("user", JSON.stringify(action.payload.info));
    },
    clearUser: (state) => {
      state.user = {
        token: "",
        full_name: "",
        image_url: "",
      };
      localStorage.removeItem("user");
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
