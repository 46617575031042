import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
const fetchData = async ({ Url, header, body, t }) => {
  try {
    const response = await axios.get(Url, {
      headers: header, // Correctly structured headers
      params: body, // Use params for GET request
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      // Handle specific network errors based on status code
      const statusCode = error.response.status;
      if (statusCode === 401) {
        message.error(t("error.401"), 2.5);
      } else if (statusCode >= 400 && statusCode < 600) {
        message.error(t("error.404"), 2.5);
      } else {
        message.error(t("error.ather"), 2.5);
      }
    } else {
      message.error(t("error.network"), 2.5);
    }
    console.error("Error submitting the form:", error);
  }
};

export const useGetData = ({ Url, key, header, body, refetchInterval }) => {
  const { t } = useTranslation();
  return useQuery({
    queryKey: [key],
    refetchInterval,
    queryFn: () => fetchData({ Url, header, body, t }),
  });
};
