import Stuffs from "../Pages/Stuffs/Stuffs";
import MainLayout from "../Pages/Dashboard/Dashboard";
import Notfound from "../Pages/Notfound/Notfound";
import Setting from "../Pages/Settings/Settings";
import Home from "../Pages/Home/Home";

const Routes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/employees",
      element: <Stuffs />,
    },
    {
      path: "/setting",
      element: <Setting />,
    },
    {
      path: "*",
      element: <Notfound />,
    },
  ],
};

export default Routes;
