import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { MdDashboard, MdSettings } from "react-icons/md";
import { Link } from "react-router-dom";

function ListItems({ collapsed }) {
  const date = new Date();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      icon: <MdDashboard />,
      label: (
        <Link to={"/"} className="" title="home furat app">
          {t("sidebar.Dashboard")}
        </Link>
      ),
    },
    {
      key: "2",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
          />
        </svg>
      ),
      label: (
        <Link to={"/employees"} title="Stuffs furat app">
          {t("sidebar.Employees")}
        </Link>
      ),
    },
    {
      key: "3",
      icon: <MdSettings />,
      label: (
        <Link to="/setting" title="Settings furat app">
          {t("sidebar.Sittings")}
        </Link>
      ),
    },
  ];
  return (
    <div className="flex flex-col h-full justify-between rounded-xl">
      <div className="demo-logo-vertical">
        {!collapsed ? (
          <Link to="/" title="Home">
            <img
              src={require("../../assets/logo-horizental.png")}
              alt="logo"
              className="w-[100px] mx-auto my-4"
            />
          </Link>
        ) : (
          <Link to="/" title="Home">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="w-[40px] mx-auto my-2"
            />
          </Link>
        )}
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        className="h-[600px]"
      />

      <div className="max-[800px]:hidden mb-4">
        {!collapsed && (
          <>
            <Link to="/" title="Home">
              <img
                src={require("../../assets/logo-name.png")}
                alt="logo"
                className="w-[120px] mx-auto"
              />
            </Link>
            <div className="text-center">
              <p className="text-[#84714E] text-[16px]">
                Gemaakt door Spikecode Team
              </p>
              <p className="text-[#84714E] text-[16px]">
                © {date.getFullYear()} Furat Group
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ListItems;
