export const API_URL = `https://backend.panle.furatgroup.nl/api/`;
// export const API_URL = `http://192.168.1.6:8000/api/`;
// export const API_URL = `http://192.168.43.76:8000/api`;

//auth
export const LOG_IN = `${API_URL}auth/login`;
export const LOG_OUT = `${API_URL}auth/logout`;
export const FORGET_PASS = `${API_URL}admin/forget_password`;
export const REST_Pass = `${API_URL}admin/reset_password`;

//Tasks
export const GET_ALL_TASKS = `${API_URL}admin/get_all_tasks`;
export const ADD_TASK = `${API_URL}admin/add_task`;
export const EDIT_TASK = `${API_URL}admin/edit_task`;
export const DELETE_TASK = `${API_URL}admin/delete_task`;
export const DELETE_EMPLOYEE_FROM_TASK = `${API_URL}admin/delete_employee/from_task`;

//employee

export const GET_ALL_EMPLOYEES = `${API_URL}admin/get_all_employees`;
export const ADD_EMPLOYEE = `${API_URL}admin/add_employee`;
export const EDIT_EMPLOYEE = `${API_URL}admin/edit_employee`;
export const DELETE_EMPLOYEE = `${API_URL}admin/delete_employee`;
export const DELETE_FROM_ALL_TASKS = `${API_URL}admin/delete_employee/from_all_task`;

//Notification
export const GET_ALL_NOTIFICATIONS = `${API_URL}auth/get_all_my_notifications`;
export const READ_NOTIFICATION = `${API_URL}auth/read_notifications`;
// admin/delete_employee/from_task
