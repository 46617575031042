import { Menu, message } from "antd";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LOG_OUT } from "../Apis/server";
import { clearUser } from "../store/Userslice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function UserMenu() {
  // const { isDarkMode, handleToggleTheme } = useTheme();
  const { ...user } = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [messageApi] = message.useMessage();
  const handelLogout = async (user) => {
    try {
      const response = await axios.post(
        LOG_OUT,
        {
          device_token: user?.device_token,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response?.data.status === true) {
        messageApi
          .open({
            type: "loading",
            content: "Action en cours..",
            duration: 2.5,
          })
          .then(() => message.success(response?.data.msg, 2.5));
        toast.success(response.data.msg);
        dispatch(clearUser());
        nav("/login");
      } else {
        messageApi.open({
          type: "error",
          content: response?.data.msg,
        });
      }
    } catch (error) {
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };
  const items = [
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: (
        <Link onClick={() => handelLogout(user)} title="logOut">
          {t("sidebar.Logout")}
        </Link>
      ),
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: (
        <Link to="/setting" title="settings">
          {t("sidebar.Sittings")}
        </Link>
      ),
    },
    // {
    //   key: "theme",
    //   icon: <MdLightMode />,
    //   label: (
    //     <Switch
    //       checked={isDarkMode}
    //       onChange={handleToggleTheme}
    //       checkedChildren="Dark"
    //       unCheckedChildren="Light"
    //     />
    //   ),
    // },
  ];
  return (
    <>
      <Menu items={items} />
    </>
  );
}
