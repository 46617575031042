// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlOICJKJnkTo_TXYgaTX4vApTZz0TvmsU",
  authDomain: "furat-ba07f.firebaseapp.com",
  projectId: "furat-ba07f",
  storageBucket: "furat-ba07f.appspot.com",
  messagingSenderId: "460332009822",
  appId: "1:460332009822:web:0ebebd1201cf0ef707a7cf",
  measurementId: "G-2DWYFYQ9DR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

// // Handle background messages
const VITE_APP_VAPID_KEY =
  "BMaLCs3Lri4nyAo0xT0B76Lw5v67X4kcg3WkIgyrQsc4sEF2KYZl24f1mm8VhDSZ7Ys_s_xYNY9QhSk6JDQX_m0";
export const requestForToken = async () => {
  await navigator.serviceWorker.ready;
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: VITE_APP_VAPID_KEY,
    });
    if (currentToken) {
      console.log("Token obtained:", currentToken);
      // Here you can send the token to your server or save it
      return currentToken; // Return the token for further use
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (error) {
    console.error("An error occurred while retrieving token.", error);
  }
};

export { messaging, getToken, onMessage };

// import firebase from "firebase/app";
// import "firebase/messaging";
// const VITE_APP_VAPID_KEY =
//   "BMaLCs3Lri4nyAo0xT0B76Lw5v67X4kcg3WkIgyrQsc4sEF2KYZl24f1mm8VhDSZ7Ys_s_xYNY9QhSk6JDQX_m0";
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBlOICJKJnkTo_TXYgaTX4vApTZz0TvmsU",
//   authDomain: "furat-ba07f.firebaseapp.com",
//   projectId: "furat-ba07f",
//   storageBucket: "furat-ba07f.appspot.com",
//   messagingSenderId: "460332009822",
//   appId: "1:460332009822:web:0ebebd1201cf0ef707a7cf",
//   measurementId: "G-2DWYFYQ9DR",
// };

// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();

// export const getToken = async (setTokenFound) => {
//   let currentToken = "";

//   try {
//     currentToken = await messaging.getToken({ vapidKey: VITE_APP_VAPID_KEY });
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }

//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
