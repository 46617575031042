import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LOG_OUT } from "../../Apis/server";
import { toast } from "react-toastify";
import { clearUser } from "../../store/Userslice";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, message, Tabs } from "antd";

function Settings() {
  // const { isDarkMode, handleToggleTheme } = useTheme();

  const { ...user } = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  console.log(user?.device_token);
  const handelLogout = async (user) => {
    try {
      const response = await axios.post(
        LOG_OUT,
        {
          device_token: user?.device_token,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response?.data.status === true) {
        messageApi
          .open({
            type: "loading",
            content: "Action en cours..",
            duration: 2.5,
          })
          .then(() => message.success(response?.data.msg, 2.5));
        toast.success(response.data.msg);
        dispatch(clearUser());
        nav("/login");
      } else {
        messageApi.open({
          type: "error",
          content: response?.data.msg,
        });
      }
    } catch (error) {
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };

  const items = [
    {
      label: t("sittings.sitting"),
      key: "sitting",
      children: (
        <>
          {/* <div className="flex items-center gap-4">
            <Switch
              checked={isDarkMode}
              onChange={handleToggleTheme}
              checkedChildren="Dark"
              unCheckedChildren="Light"
            />
            <p className="text-[16px] text-neutral-600 font-medium">
              {t("sittings.editTheme")}
            </p>
          </div> */}
          {/* <br /> */}
          <div className="flex items-center gap-4 max-[800px]:flex-col-reverse">
            <Button
              type="default"
              color="danger"
              onClick={() => handelLogout(user)}
              title="logOut"
            >
              <LogoutOutlined /> {t("sidebar.Logout")}
            </Button>
            <p className="text-[16px] text-neutral-600 font-medium">
              {t("sittings.logout")}
            </p>
          </div>
        </>
      ),
    },
    {
      label: t("sittings.more"),
      key: "more",
      children: (
        <>
          <div className="flex items-center gap-4">{t("sittings.message")}</div>
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <div className=" bg-white mx-8 dark:bg-[#23283d]   my-20 rounded-lg shadow-sm p-8  max-[800px]:p-8">
        <Tabs type="line" tabPosition={"top"} items={items} />
      </div>
    </>
  );
}

export default Settings;
