import React from "react";
import TableComponent from "../../Components/Table";
import { GET_ALL_EMPLOYEES } from "../../Apis/server";
import { useGetData } from "../../Components/Hooks/fetchData";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Errorpage from "../../Components/Errorpage";

function Stuffs() {
  const token = useSelector((state) => state.user.user.token);
  const { isLoading, isError, error, data } = useGetData({
    Url: GET_ALL_EMPLOYEES,
    key: "Tasks",
    header: {
      Authorization: `Bearer ${token}`, // Correct the quote and remove the extra brace
    },
  });
  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <Errorpage title={error.message} />;
  }

  return (
    <div className="mt-16">
      <TableComponent data={data} />
    </div>
  );
}

export default Stuffs;
