import axios from "axios";
import { message } from "antd";
import { DELETE_TASK } from "../../Apis/server";

export const handelDeleteTask = async (task_id, messageApi, token) => {
  try {
    const formdata = new FormData();
    formdata.append("task_id", task_id);

    const response = await axios.post(DELETE_TASK, formdata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    messageApi
      .open({
        type: "loading",
        content: "Action in progress..",
        duration: 2.5,
      })
      .then(() => {
        message.success(response.data.msg, 2.5);
        window.location.reload();
      });
  } catch (error) {
    console.error("Error", error);
  }
};
