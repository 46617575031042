import React, { useEffect, useState } from "react";
import NavBar from "../../Components/Header";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Layout, theme } from "antd";
import "notificationapi-js-client-sdk/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import ListItems from "./ListItems";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";
import { changecollapsed } from "../../store/collapsedSlice";
import { onMessage } from "@firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
import { messaging } from "../../Firebase/firebase";
import { useTranslation } from "react-i18next";
const Bread = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumb
      items={[
        {
          href: "/",
          title: <HomeOutlined />,
        },
        {
          title: (
            <>
              <span className="text-sm">
                {pathname === "/" ? "" : pathname.split("/")}
              </span>
            </>
          ),
        },
      ]}
    />
  );
};

function Dashboard() {
  const { t } = useTranslation();
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("../public/firebase-messaging-sw.js")

        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
    onMessage(messaging, (payload) => {
      toast(payload.data.title || `${t("notification.New")}`);
    });
  }, []);
  const token = useSelector((state) => state.user.user.token);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  const dispatsh = useDispatch();
  return !token ? (
    <Navigate to="/login" />
  ) : (
    <Layout style={{ display: "flex" }}>
      <Sider
        trigger={null}
        collapsible
        width={250}
        collapsed={collapsed}
        style={{
          background: colorBgContainer,
          scrollbarWidth: "thin",
          scrollbarColor: "unset",
          height: "100vh",
        }}
        className="fixed overflow-y-auto inset-0 top-0 bottom-0 shadow-md max-[800px]:hidden "
      >
        <ListItems collapsed={collapsed} />
      </Sider>
      <Layout
        style={{
          backgroundColor: isDarkMode ? "#141723" : "#fefefe",
          borderRadius: 6,
          minHeight: "100vh", // Make sure the content takes full height
        }}
        className={`${
          collapsed ? "ml-[80px]" : " ml-[250px]"
        } max-[800px]:ml-0`}
      >
        <Header
          className={`bg-[#fefefe] dark:bg-[#23283d]
           max-[800px]:ml-0
           ${collapsed ? "ml-[80px]" : " ml-[250px]"} 
        shadow-sm
          h-[70px] flex justify-between items-center p-2 fixed top-0 left-0
          right-0 z-10 max-[800px]:px-6`}
          style={{
            zIndex: 10, // Ensure the header is above other content
          }}
        >
          <div className="flex items-center gap-4">
            <Button
              className="hidden md:block" // Hide on small screens
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                setCollapsed(!collapsed);
                dispatsh(changecollapsed(collapsed));
              }}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <Bread />
          </div>
          <div className="px-2">
            <NavBar />
          </div>
        </Header>

        <Content
          style={{
            marginTop: "10px",
            padding: "0 4px",
            minHeight: 280,
            backgroundColor: isDarkMode ? "#141723" : "#f9f9f9",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default Dashboard;
