import React from "react";
import "./loader.css";
function Loader() {
  return (
    <div className="flex items-center h-[100vh] justify-center flex-col m-auto  dark:bg-gray-900">
      <span className="loader"></span>
    </div>
  );
}

export default Loader;
