import React from "react";
import { Button, Divider, List, Result, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdDone, MdError, MdInfo } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { usePostData } from "./Hooks/postData";
import { READ_NOTIFICATION } from "../Apis/server";

const NotificationMenu = ({ isLoading, isError, error, data, token }) => {
  const { t } = useTranslation();

  const notifications = data?.notifications.notifications || [];

  // Call the usePostData hook unconditionally
  const { mutate } = usePostData({
    Url: READ_NOTIFICATION,
    key: "ReadNotification",
    header: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Handle loading and error states
  if (isLoading) {
    return (
      <>
        <div
          id="scrollableDiv"
          className="bg-white dark:bg-gray-900 shadow-lg"
          style={{
            width: 400,
            height: 400,
            overflow: "auto",
            padding: "0 16px",

            borderRadius: "8px",
          }}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <div
          id="scrollableDiv"
          className="bg-white dark:bg-gray-900 shadow-lg"
          style={{
            width: 400,
            height: 400,
            overflow: "auto",
            padding: "0 16px",

            borderRadius: "8px",
          }}
        >
          <Result
            style={{
              fontSize: "14px",
            }}
            status="warning"
            title={
              <p className="text-[16px] font-medium ">{t("error.network")}</p>
            }
          />
        </div>
      </>
    );
  }

  const loadMoreData = () => {
    if (isLoading) {
      return;
    }
    // Logic to load more data if applicable
  };

  const ReadAll = () => {
    // Call mutate without any data if your API does not require it
    mutate();
  };

  return (
    <>
      <div
        id="scrollableDiv"
        className="bg-white dark:bg-gray-900 shadow-lg w-[400px] h-[400px] max-[800px]:w-[300px] "
        style={{
          overflow: "auto",
          padding: "0 16px",

          borderRadius: "8px",
        }}
      >
        <InfiniteScroll
          dataLength={notifications.length}
          next={loadMoreData}
          hasMore={notifications.length < 3} // Adjust based on your pagination logic
          loader={
            <Skeleton
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={
            <Divider plain className="m-3">
              {t("notification.Note")}
            </Divider>
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            className="bg-white dark:bg-gray-900"
            header={
              <div className="flex justify-between">
                <h1 className="font-medium">
                  {t("notification.notification")}
                </h1>
                <Button
                  title={t("notification.read_all")}
                  type="text"
                  onClick={ReadAll}
                  className="text-primary text-[14px] "
                >
                  {t("notification.read_all")}
                </Button>
              </div>
            }
            dataSource={notifications}
            renderItem={(notification) => (
              <List.Item className="bg-white dark:bg-gray-900">
                <List.Item.Meta
                  className={`${
                    notification.is_read === 0 ? "bg-gray-100" : "bg-white"
                  } py-1 px-3 bg-white dark:bg-gray-900 rounded-md `}
                  avatar={
                    <Button
                      shape="circle"
                      type="default"
                      icon={
                        (notification.type === "warning" && (
                          <BiSolidError className="text-orange-500" />
                        )) ||
                        (notification.type === "info" && (
                          <MdInfo className="text-blue-600" />
                        )) ||
                        (notification.type === "error" && (
                          <MdError className="text-red-600" />
                        )) ||
                        (notification.type === "success" && (
                          <MdDone className="text-green-600" />
                        ))
                      }
                    />
                  }
                  title={
                    <p className="text-[16px] text-neutral-700 dark:text-neutral-200 font-medium">
                      {notification.title}
                    </p>
                  }
                  description={
                    <p className="text-[14px] text-neutral-500 dark:text-neutral-400 font-medium">
                      {notification.body}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default NotificationMenu;
