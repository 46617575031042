import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Function to handle the POST request
const postData = async ({ Url, header, body, t }) => {
  try {
    const response = await axios.post(Url, body, {
      headers: header, // Correctly structured headers
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      // Handle specific network errors based on status code
      const statusCode = error.response.status;
      if (statusCode === 401) {
        message.error(t("error.401"), 2.5);
      } else if (statusCode >= 400 && statusCode < 600) {
        message.error(t("error.404"), 2.5);
      } else {
        message.error(t("error.ather"), 2.5);
      }
    } else {
      message.error(t("error.network"), 2.5);
    }
    console.error("Error submitting the form:", error);
  }
};

// Custom hook for POST requests
export const usePostData = ({ Url, key, header, body }) => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: [key], // This key can be used to identify the mutation
    mutationFn: () => postData({ Url, header, body, t }), // Function to call with body
    onSuccess: (data) => {
      window.location.reload();

      // updateDataFunction(data); // استدعاء الدالة لتحديث البيانات
    },
  });
};
