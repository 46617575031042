import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { RiProgress8Line } from "react-icons/ri";
import { HiMiniUsers } from "react-icons/hi2";
import { MdCall, MdDescription } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { MdAccessTime } from "react-icons/md";
import { IoRestaurantSharp } from "react-icons/io5";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  message,
  Popconfirm,
  Steps,
  Tag,
} from "antd";
import FormComponent from "../../Form/FormComponent";
import { useSelector } from "react-redux";
import { handelDeleteTask } from "../../Hooks/handelDeleteTask";

function Todo({ ...data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [EditData, setEditData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const token = useSelector((state) => state.user.user.token);

  const showModal = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };
  const showDrawer = (task) => {
    // console.log(task);
    setIsModalVisible(false);
    setOpen(!open);
    setEditData(task);
  };
  return (
    <>
      {contextHolder}
      {/* title card */}
      <div className="flex items-center justify-between mb-3 max-[800px]:w-full">
        <div className="flex items-center gap-4">
          <span>
            <RiProgress8Line />
          </span>
          <h2 className="font-medium text-neutral-700">
            {t("Tasks.inReview")}
          </h2>
        </div>
        {data.to_do_tasks?.length !== 0 && (
          <span className="text-sm px-2 font-medium border rounded-sm bg-neutral-300">
            {data.to_do_tasks?.length}
          </span>
        )}
      </div>
      {/*  cards */}
      {data.to_do_tasks?.length !== 0 ? (
        <div className="flex flex-col items-center gap-3">
          {data.to_do_tasks?.map((el) => (
            <Card
              style={{ width: "100%" }}
              key={el.id}
              className=" rounded-lg text-sm shadow-md"
            >
              <Card.Meta
                style={{ padding: "2px" }}
                description={
                  <>
                    <div className="flex flex-col  p-2 border border-neutral-100 rounded-md">
                      <Tag
                        color="purple"
                        style={{
                          padding: "0 8px",
                          lineHeight: "1.5",
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {t("Tasks.inReview")}
                      </Tag>

                      <h1 className=" my-3 text-[16px] text-neutral-500 font-medium">
                        {el.address}
                      </h1>
                    </div>

                    <div className="flex gap-3 mt-3">
                      <div className="flex gap-2 items-center text-neutral-600">
                        <span>
                          <MdAccessTime className="text-blue-900 " />
                        </span>
                        <p className="text-[14px] font-medium ">
                          {t("Add_Tasks.Task_start")}:
                        </p>
                      </div>
                      <div className="flex gap-3 m-2">
                        <div className="w-1 h-1 bg-indigo-500 rounded-full my-2" />
                        <h1 className=" text-[16px] text-neutral-500 font-medium">
                          {el.start_time}
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex gap-2 items-center text-neutral-600">
                        <span>
                          <MdAccessTime className="text-blue-900 " />
                        </span>
                        <p className="text-[14px] font-medium">
                          {t("Add_Tasks.Task_end")}
                        </p>
                      </div>
                      <div className="flex gap-3 m-2">
                        <div className="w-1 h-1 bg-indigo-500 rounded-full my-2" />
                        <h1 className=" text-[16px] text-neutral-500 font-medium">
                          {el.end_time}
                        </h1>
                      </div>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <HiMiniUsers className="text-primary" />
                        <h1 className="text-neutral-600 font-medium">
                          {el.number_of_employees}
                        </h1>
                      </div>
                      <div className="flex ">
                        <Button shape="circle" type="text">
                          <EyeOutlined
                            onClick={() => showModal({ ...el })}
                            style={{ color: "#20509D" }}
                            title="View"
                          />
                        </Button>
                        <Button
                          shape="circle"
                          type="text"
                          onClick={() => {
                            showDrawer({ ...el });
                          }}
                        >
                          <EditOutlined style={{ color: "#9529B9" }} />
                        </Button>
                        <Popconfirm
                          title={t("message.Delete_Task")}
                          description={t("message.Delete_Task_message")}
                          okText={t("buttons.delete")}
                          cancelText={t("buttons.cancele")}
                          overlayStyle={{ fontSize: "16px" }}
                          onConfirm={() =>
                            handelDeleteTask(el.id, messageApi, token)
                          }
                        >
                          <Button shape="circle" type="text">
                            <DeleteOutlined style={{ color: "#E12E2E" }} />
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </>
                }
              />
            </Card>
          ))}
        </div>
      ) : (
        <div className="mt-20">
          <Empty description={t("Add_Tasks.empty_inreview")} />
        </div>
      )}
      <Drawer
        title="Edit"
        width={900}
        onClose={() => {
          setOpen(!open);
          setEditData(null);
        }}
        open={open}
      >
        <FormComponent task={EditData} />
      </Drawer>
      {isModalVisible && selectedItem && (
        <Drawer
          style={{ padding: "20px" }}
          title={t("Add_Tasks.Task_Details")}
          width={600}
          open={isModalVisible}
          onClose={handleCancel}
          onOk={handleCancel}
        >
          <div className="p-4">
            <Steps
              style={{ width: "100%" }}
              direction="horizontal"
              items={[
                {
                  title: t("Add_Tasks.Address"),
                  status: "finish",
                  icon: <GrMapLocation />,
                  description: selectedItem.address,
                },
                {
                  title: t("Add_Tasks.Task_start"),
                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.start_time,
                },
                {
                  title: t("Add_Tasks.Task_end"),
                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.end_time,
                },
              ]}
            />
            <br />
            <Steps
              direction="horizontal"
              style={{ width: "100%" }}
              items={[
                {
                  title: t("Add_Tasks.employee"),
                  key: "Employees",
                  status: "finish",
                  icon: <HiMiniUsers />,
                  description: selectedItem.number_of_employees,
                },
                {
                  title: t("Add_Tasks.break"),
                  key: "Break time",
                  status: "finish",
                  icon: <IoRestaurantSharp />,
                  description: selectedItem.rest_time,
                },
                {
                  title: t("Add_Tasks.Total"),
                  key: "Total time",

                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.total_time,
                },
              ]}
            />
          </div>

          <div className="m-5">
            <div className="flex items-center gap-2 mb-2 text-neutral-700 font-medium">
              <span>
                <MdCall className="text-primary text-[24px]" />
              </span>
              <span className="text-neutral-600 font-normal text-[18px]">
                {t("Add_Tasks.For_contact")}: {selectedItem.contact_information}
              </span>
            </div>
            {selectedItem.description && (
              <div className="flex items-center gap-2 mb-2 text-neutral-700 font-medium">
                <span>
                  <MdDescription className="text-primary text-[24px]" />
                </span>
                <span className="text-neutral-600 font-normal text-[18px]">
                  {t("Add_Tasks.description")}: {selectedItem.description}
                </span>
              </div>
            )}
          </div>
          <div className="mx-4 my-10 flex gap-3">
            <Button
              onClick={handleCancel}
              size="middle"
              type="default"
              className="w-full"
            >
              {t("Add_Tasks.cancel")}
            </Button>
            <Button
              icon={<EditOutlined />}
              size="middle"
              type="primary"
              className="w-full"
              onClick={() => {
                showDrawer({ ...selectedItem });
              }}
            >
              {t("Add_Tasks.edit")}
            </Button>
          </div>
        </Drawer>
      )}
    </>
  );
}

export default Todo;
