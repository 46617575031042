import { useRoutes } from "react-router-dom";
import AuthenticationRoutes from "./Auth";
import Routes from "./Routes";

// routes

// ==============================|| ROUTING RENDER ||============================== //
export default function MainRouts() {
  return useRoutes([AuthenticationRoutes, Routes]);
}
