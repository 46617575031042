import { Button, Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Errorpage({ title }) {
  const { t } = useTranslation();
  const nav = useNavigate();
  return (
    <div className="h-[100vh]   flex justify-center m-auto items-center">
      <Result
        status={"404"}
        title={title}
        subTitle={t("buttons.subTitle")}
        extra={
          <Button title="Home" onClick={() => nav("/")} type="primary">
            {t("buttons.back_home")}
          </Button>
        }
      />
    </div>
  );
}

export default Errorpage;
