import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { HiMiniUsers } from "react-icons/hi2";
import { MdCall, MdDelete, MdDescription } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { MdAccessTime } from "react-icons/md";
import { IoList, IoRestaurantSharp } from "react-icons/io5";
import { FaRegCheckSquare } from "react-icons/fa";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Image,
  List,
  message,
  Modal,
  Popconfirm,
  Steps,
  Tag,
} from "antd";
import FormComponent from "../../Form/FormComponent";
import { useSelector } from "react-redux";
import { DELETE_EMPLOYEE_FROM_TASK } from "../../../Apis/server";
import axios from "axios";

function Todo({ ...data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [EditData, setEditData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const token = useSelector((state) => state.user.user.token);
  const [isEmployeeModal, setisEmployeeModal] = useState(false);
  const [selectedListEmployee, setSelectedListEmployee] = useState(null);
  const showModal = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };
  const showDrawer = (task) => {
    setIsModalVisible(false);
    setOpen(!open);
    setEditData(task);
  };
  const handleCancelEmployeeModal = () => {
    setisEmployeeModal(false);
    setSelectedListEmployee(null);
  };
  const handelDeleteEmployee = async (selectedListEmployee, index) => {
    var requestOptions = {
      body: {
        task_id: selectedListEmployee.id,
        employee_id: index,
      },
    };
    try {
      const response = await axios.post(
        DELETE_EMPLOYEE_FROM_TASK,
        { ...requestOptions.body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setisEmployeeModal(false);

      if (response?.data.status === true) {
        message.success(response.data.msg, 2.5);
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: response.data.msg,
        });
      }
    } catch (error) {
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };
  return (
    <>
      {contextHolder}
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-4">
          <span>
            <FaRegCheckSquare />
          </span>
          <h2 className="font-medium text-neutral-700">{t("Tasks.Done")}</h2>
        </div>
        {data.done_Tasks?.length !== 0 && (
          <span className=" text-sm px-2 font-medium border rounded-sm bg-green-200 dark:bg-[#9ae9b2]">
            {data.done_Tasks?.length}
          </span>
        )}
      </div>
      {data.done_Tasks?.length !== 0 ? (
        <div className="flex flex-col items-center gap-3">
          {data.done_Tasks?.map((el) => (
            <Card
              style={{ width: "100%" }}
              key={el.id}
              className=" rounded-lg text-sm shadow-md"
            >
              <Card.Meta
                style={{ padding: "2px" }}
                description={
                  <>
                    <div className="flex flex-col w-full p-2 border border-neutral-100 rounded-md align-items-center">
                      <Tag
                        color="success"
                        className="w-1/4 flex items-center   "
                      >
                        <p className="m-auto">{t("Tasks.Done")}</p>
                      </Tag>
                      <div className="flex gap-3 my-2">
                        <div className="w-1 h-1 bg-green-600 rounded-full my-2" />
                        <h1 className=" text-[16px] text-neutral-500 font-medium">
                          {el.address}
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-3 mt-3">
                      <div className="flex gap-2 items-center text-neutral-600">
                        <span>
                          <MdAccessTime className="text-blue-900 " />
                        </span>
                        <p className="text-[14px] font-medium ">
                          {t("Add_Tasks.Task_start")}
                        </p>
                      </div>
                      <div className="flex gap-3 m-2">
                        <div className="w-1 h-1 bg-indigo-500 rounded-full my-2" />
                        <h1 className=" text-[16px] text-neutral-500 font-medium">
                          {el.start_time}
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex gap-2 items-center text-neutral-600">
                        <span>
                          <MdAccessTime className="text-blue-900 " />
                        </span>
                        <p className="text-[14px] font-medium">
                          {t("Add_Tasks.Task_end")}
                        </p>
                      </div>
                      <div className="flex gap-3 m-2">
                        <div className="w-1 h-1 bg-indigo-500 rounded-full my-2" />
                        <h1 className=" text-[16px] text-neutral-500 font-medium">
                          {el.end_time}
                        </h1>
                      </div>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                      <div className="mx-4  flex gap-5 items-center">
                        <div className="-mx-0.5 flex justify-center -space-x-3">
                          {el.employees.map((image) => (
                            <Image
                              className=" rounded-full shadow-md"
                              src={
                                image.employee.information_personal.image_url
                              }
                              width={32}
                              height={32}
                              alt="image.employee.information_personal.image_url"
                            />
                          ))}
                        </div>
                        <Button
                          type="default"
                          onClick={() => {
                            setisEmployeeModal(!isEmployeeModal);
                            setSelectedListEmployee(el);
                          }}
                          className="text-[16px] font-medium flex gap-2 items-center  cursor-pointer hover:bg-gray-200 rounded-md"
                        >
                          <span>
                            <IoList className="my-0 text-[18px]" />
                          </span>
                        </Button>
                      </div>
                      <div className="flex ">
                        <Button
                          shape="circle"
                          type="text"
                          title="Task details"
                          onClick={() => showModal({ ...el })}
                        >
                          <EyeOutlined style={{ color: "#20509D" }} />
                        </Button>
                      </div>
                    </div>
                  </>
                }
              />
            </Card>
          ))}
        </div>
      ) : (
        <div className="mt-20">
          <Empty description={t("Add_Tasks.empty_inreview")} />
        </div>
      )}
      <Drawer
        title="Edit"
        width={900}
        onClose={() => {
          setOpen(!open);
          setEditData(null);
        }}
        open={open}
      >
        <FormComponent task={EditData} />
      </Drawer>
      {isModalVisible && selectedItem && (
        <Drawer
          style={{ padding: "20px" }}
          title={t("Add_Tasks.Task_Details")}
          width={600}
          open={isModalVisible}
          onClose={handleCancel}
          onOk={handleCancel}
        >
          <div className="p-4">
            <Steps
              style={{ width: "100%" }}
              direction="horizontal"
              items={[
                {
                  title: t("Add_Tasks.Address"),
                  status: "finish",
                  icon: <GrMapLocation />,
                  description: selectedItem.address,
                },
                {
                  title: t("Add_Tasks.Task_start"),
                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.start_time,
                },
                {
                  title: t("Add_Tasks.Task_end"),
                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.end_time,
                },
              ]}
            />
            <br />
            <Steps
              direction="horizontal"
              style={{ width: "100%" }}
              items={[
                {
                  title: t("Add_Tasks.employee"),
                  key: "Employees",
                  status: "finish",
                  icon: <HiMiniUsers />,
                  description: selectedItem.number_of_employees,
                },
                {
                  title: t("Add_Tasks.break"),
                  key: "Break time",
                  status: "finish",
                  icon: <IoRestaurantSharp />,
                  description: selectedItem.rest_time,
                },
                {
                  title: t("Add_Tasks.Total"),
                  key: "Total time",

                  status: "finish",
                  icon: <MdAccessTime />,
                  description: selectedItem.total_time,
                },
              ]}
            />
          </div>

          <div className="m-5">
            <div className="flex items-center gap-2 mb-2 text-neutral-700 font-medium">
              <span>
                <MdCall className="text-primary text-[24px]" />
              </span>
              <span className="text-neutral-600 font-normal text-[18px]">
                {t("Add_Tasks.For_contact")}: {selectedItem.contact_information}
              </span>
            </div>
            {selectedItem.description && (
              <div className="flex items-center gap-2 mb-2 text-neutral-700 font-medium">
                <span>
                  <MdDescription className="text-primary text-[24px]" />
                </span>
                <span className="text-neutral-600 font-normal text-[18px]">
                  {t("Add_Tasks.description")}: {selectedItem.description}
                </span>
              </div>
            )}
          </div>
          <div className="mx-4 my-10 flex gap-3">
            <Button
              onClick={handleCancel}
              size="middle"
              type="default"
              className="w-full"
            >
              {t("Add_Tasks.cancel")}
            </Button>
            <Button
              icon={<EditOutlined />}
              size="middle"
              type="primary"
              className="w-full"
              onClick={() => {
                showDrawer({ ...selectedItem });
              }}
            >
              {t("Add_Tasks.edit")}
            </Button>
          </div>
        </Drawer>
      )}
      <Modal
        title={t("Tasks.employeebooked")}
        open={isEmployeeModal}
        onCancel={handleCancelEmployeeModal}
        footer={null}
      >
        <List
          dataSource={selectedListEmployee?.employees}
          itemLayout="horizontal"
          renderItem={(item, index) => (
            <List.Item
              key={index}
              actions={[
                <Popconfirm
                  title={t("message.Delete_Employee_from_task")}
                  description={t("message.delete_empployee_from_task_message")}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() =>
                    handelDeleteEmployee(selectedListEmployee, item.employee.id)
                  }
                >
                  <Button type="default" shape="circle">
                    <MdDelete className="text-red-500" />
                  </Button>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={item.employee.information_personal.image_url} />
                }
                title={<p>{item.employee.information_personal.full_name}</p>}
                description={
                  <div>
                    <p>{item.employee.information_personal.phone_number}</p>
                    <p>
                      {t("Add_Tasks.employee_chosen")} :
                      {item.action.number_of_employee_chosen}
                    </p>
                    {item.action.note && (
                      <p>
                        {t("Add_Tasks.note")}:{item.action.note}
                      </p>
                    )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}

export default Todo;
