import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Spin,
  TimePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ADD_TASK, EDIT_TASK } from "../../Apis/server";
import { useSelector } from "react-redux";
import moment from "moment/moment";

function FormComponent({ task, name, showDrawer }) {
  const [form] = Form.useForm();
  const [loading, SetLoading] = useState(false);
  const initialValues = task
    ? {
        task_id: task?.id,
        start: moment(task?.start_time, "hh:mm A"),
        end: moment(task?.end_time, "hh:mm A"),
        date_select: moment(task?.date, "YYYY-MM-DD"),
        description: task?.description,
        number_of_employees: task?.number_of_employees,
        rest_time: task?.rest_time,
        contact_information: task?.contact_information,
        address: task?.address,
      }
    : {};
  useEffect(() => {
    form.setFieldsValue(task); // تعيين القيم عند تغيير task
  }, [task]);

  const token = useSelector((state) => state.user.user.token);

  const onFinish = async (values) => {
    const formattedValues = {
      ...values,
      start_time: values?.start.format("hh:mm A"),
      end_time: values?.end.format("hh:mm A"),
      date: values?.date_select.format("YYYY-MM-DD"),
    };

    try {
      SetLoading(true);
      const apiUrl = task ? EDIT_TASK : ADD_TASK;

      const res = await axios.post(apiUrl, formattedValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (res.data.status === true) {
        message.success(res.data.msg, 2.5);
        SetLoading(false);
        showDrawer();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        // Handle validation or other specific errors from the server
        if (res.data.errors) {
          const errorMessages = res.data.errors.map((error) => error.message);
          message.error(errorMessages.join("\n"), 2.5);
        } else {
          message.error(res.data.msg, 2.5);
        }
        SetLoading(false);
      }
    } catch (error) {
      SetLoading(false);
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <Form
        initialValues={initialValues}
        name={name || `Form-${Math.random().toString(36).substring(2, 7)}`}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="task_id" hidden>
              <Input hidden />
            </Form.Item>
            <Form.Item
              name="address"
              label={t("Add_Tasks.Address")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_Address"),
                },
              ]}
            >
              <Input
                size="middle"
                placeholder={t("Add_Tasks.placeholder_Address")}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="contact_information"
              label={t("Add_Tasks.contact")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_contact"),
                },
              ]}
            >
              <Input
                size="middle"
                placeholder={t("Add_Tasks.placeholder_contact")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="number_of_employees"
              label={t("Add_Tasks.Num_employee")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.message_Num_employee"),
                },
                {
                  type: "number",
                  min: 1,
                  message: t("Add_Tasks.Error_message_Num_employee"),
                },
              ]}
            >
              <InputNumber
                size="middle"
                type="number"
                className="w-full"
                placeholder={t("Add_Tasks.placeholder_Num_employee")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="date_select"
              className="w-full"
              label={t("Add_Tasks.date")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_date"),
                },
              ]}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="start"
              className="w-full"
              label={t("Add_Tasks.Task_start")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_Task_start"),
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="middle"
                use12Hours
                format="hh:mm A"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="end"
              className="w-full"
              label={t("Add_Tasks.Task_end")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_Task_end"),
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="middle"
                use12Hours
                format="hh:mm A"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="rest_time"
              label={t("Add_Tasks.break")}
              rules={[
                {
                  required: true,
                  message: t("Add_Tasks.placeholder_break"),
                },
              ]}
            >
              <Input
                size="middle"
                placeholder={t("Add_Tasks.placeholder_break")}
                type="number"
                rules={[
                  {
                    required: true,
                    message: t("Add_Tasks.placeholder_Num_employee"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item
              name="description"
              label={t("Add_Tasks.description")}
              rules={[
                {
                  message: t("Add_Tasks.placeholder_description"),
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                size="middle"
                placeholder={t("Add_Tasks.placeholder_description")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Button xs={12} size="large" type="primary" htmlType="submit">
          {loading ? (
            <>
              <Spin
                indicator={<LoadingOutlined spin />}
                size="small"
                style={{
                  fontSize: 16,
                  color: "#fff",
                }}
              />
            </>
          ) : task ? (
            t("buttons.Edit_task")
          ) : (
            t("buttons.Add_new_task")
          )}
        </Button>
      </Form>
    </>
  );
}

export default FormComponent;
