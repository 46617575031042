import { lazy } from "react";
import React from "react";

// project imports

import Loadable from "../Components/Loadable";
import MinimalLayout from "../Pages/Dashboard/MinimalLayout";
import Notfound from "../Pages/Notfound/Notfound";

// login option 3 routing
const Login = Loadable(lazy(() => import("../Pages/Auth/Login")));
const ForgotPasswordForm = Loadable(
  lazy(() => import("../Pages/Auth/ForgetPass"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/*",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "forgot-password",
      element: <ForgotPasswordForm />,
    },
    {
      path: "*",
      element: <Notfound />,
    },
  ],
};

export default AuthenticationRoutes;
