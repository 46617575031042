import React from "react";
import Errorpage from "../../Components/Errorpage";
import { useTranslation } from "react-i18next";

function Notfound() {
  const { t } = useTranslation();
  return <Errorpage title={t("sittings.notfound")} />;
}

export default Notfound;
